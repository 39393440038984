<template>
    <div>
        <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialog">
            <v-card>
                <v-toolbar dense color="primary" class="elevation-0 white--text">
                    <v-toolbar-title>Timesheet Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="close">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="pa-4">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                            <p class="text-h6 mb-0 font-weight-regular">Shift Info</p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Work Date" :text="object.work_date"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Start Time" :text="object.start_time"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="End Time" :text="object.end_time"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Break Time (Hour)" :text="object.break_time?object.break_time.toString():''"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Total Hours" :text="object.total_hours?object.total_hours.toString():''"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Hourly Rate (S$)" :text="object.hourly_rate?object.hourly_rate.toString():''"></display-label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                            <p class="text-h6 mb-0 font-weight-regular">Promoter Info</p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Full Name" :text="object.promoter.name"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Mobile Number" :text="object.promoter.mobile_number"></display-label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                            <p class="text-h6 mb-0 font-weight-regular">Activity Photo Info</p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <display-label label="No Activity Photo Yet" v-if="!object.attendance"></display-label>
                            <div><v-img width="100" :src="object.attendance.image_url" v-if="object.attendance && object.attendance.image_url"></v-img></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                            <p class="text-h6 mb-0 font-weight-regular">Sales Info</p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center"></th>
                                            <th class="text-center">SKU Name</th>
                                            <th class="text-center">Price (S$)</th>
                                            <th class="text-center">Promotion Price (S$)</th>
                                            <th class="text-center">Quantity</th>
                                            <th class="text-center">Total Sales (S$)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="index" v-for="(item, index) in object.sales">
                                            <td class="text-center">
                                                <v-img width="100" class="mx-auto" :src="item.image_url"></v-img>
                                            </td>
                                            <td class="text-center" v-text="item.name"></td>
                                            <td class="text-center" v-text="item.price"></td>
                                            <td class="text-center" v-text="item.promotion_price"></td>
                                            <td class="text-center" v-text="item.quantity"></td>
                                            <td class="text-center" v-text="item.total_sales"></td>
                                        </tr>
                                        <tr v-if="object.sales.length === 0">
                                            <td colspan="6" class="text-center">No sales yet</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                            <p class="text-h6 mb-0 font-weight-regular">Feedbacks</p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center">S/N</th>
                                            <th class="text-center">Message</th>
                                            <th class="text-center">Attachment Images</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="index" v-for="(item, index) in object.feedbacks">
                                            <td class="text-center" v-text="index + 1"></td>
                                            <td class="text-center" v-text="item.message"></td>
                                            <td class="text-center">
                                                <div class="d-flex justify-center">
                                                    <v-img max-width="50" class="mx-3 pointer" :key="sindex" :src="sitem.thumbnail_url" @click="openImageDialog(sitem)" v-for="(sitem, sindex) in item.images"></v-img>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="object.feedbacks.length === 0">
                                            <td colspan="3" class="text-center">No feedback yet</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                            <p class="text-h6 mb-0 font-weight-regular">Outlet Visit Feedback</p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Visitor" :text="object.visit_feedback.visitor"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Performance" :text="object.visit_feedback.performance.text"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Crowed Feedback" :text="object.visit_feedback.feedback.text"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <display-label label="Remarks" :text="object.visit_feedback.remarks"></display-label>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <div class="d-flex justify-center">
                                <v-img max-width="50" class="mx-3 pointer" :key="index" :src="item.thumbnail_url" @click="openImageDialog(item)" v-for="(item, index) in object.visit_feedback.images"></v-img>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <image-dialog ref="imageDialog"></image-dialog>
	</div>
</template>


<script>

import DisplayLabel from '../../../shared/DisplayLabel.vue'
import ImageDialog from '../../../shared/ImageDialog.vue'

export default {
    name: 'TimesheetShiftInfoDialog',
    components: {
        displayLabel: DisplayLabel,
        imageDialog: ImageDialog,
    },
    data() {
        return {
            valid: true,
            dialog: false,
            loading: false,
            filters: {
                campaign: '',
                shift: '',
            },
            object: {
                promoter: {},
                attendance: {},
                sales: [],
                feedbacks: [],
                visit_feedback: {
                    performance: {},
                    feedback: {},
                    visitor: '',
                    remarks: '',
                    images: [],
                },
            }
        }
    },
    computed: {
        imageDialog() {
            return this.$refs.imageDialog
        }
    },
    methods: {
        open: function() {
            this.dialog = true
            this.get()
        },
        close: function() {
            this.dialog = false
            this.filters = { campaign: '', shift: '' }
            this.object = { promoter: {}, attendance: {}, sales: [], feedbacks: [], visit_feedback: { performance: {}, feedback: {}, visitor: '', remarks: '', images: [], }, }
        },
        updateObject: function(item) {
            this.filters = item
        },
        get: function(){
            this.loading = true

            this.$store.dispatch('epanel/project/getCampaignTimesheetShift', { ids: {campaign: this.filters.campaign, shift: this.filters.shift }}).then((response) => {
                this.object = response.data.object
                this.object.sales = response.data.sales
                this.object.feedbacks = response.data.feedbacks
                this.object.visit_feedback = response.data.visit_feedback
                this.object.attendance = response.data.attendance
            }).catch((errors) => {
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
            })
        },
        openImageDialog: function(item) {
            this.imageDialog.updateImageUrl(item.image_url)
            this.imageDialog.open()
        }
    }
}

</script>


<style scoped>
/deep/ .text-label {
    font-size: 1rem !important;
    font-weight: 500 !important;
}

</style>