<template>
    <div>
        <v-row v-if="!loading && !permissions.can_view">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Project Campaign Shift Timesheet</p>
                <p class="text-title mb-0">You do not have permission to view project campaign shift timesheet</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_view">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">Project Campaign Info</v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="Project Name" :text="object.project"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="Campaign Name" :text="object.name"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="Start Date" :text="object.start_date.text"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="End Date" :text="object.end_date.text"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <display-label list label="Project Managers" v-if="object.managers.length > 0">
                                    <div slot="list">
                                        <v-chip small label color="primary" class="mr-2 mt-2" :key="index" v-for="(item, index) in object.managers"><span v-text="item.text"></span></v-chip>
                                    </div>
                                </display-label>
                                <display-label label="Project Managers" text="N.A." v-if="object.managers.length == 0"></display-label>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">Promotion SKUs
                                <p class="text-center mb-0" v-if="skus.length === 0">No promotion SKU is found</p>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3" :key="index" v-for="(item, index) in skus">
                                <v-card class="elevation-0">
                                    <v-img contain max-height="80" :src="item.image_url"></v-img>
                                    <v-card-title class="justify-center text-subtitle-2" v-text="item.name"></v-card-title>
                                    <v-card-text class="d-flex justify-space-between text-subtitle-2 pb-0">
                                        <span>Retail Price</span>
                                        <span class="primary--text" v-text="item.retail_price"></span>
                                    </v-card-text>
                                    <v-card-text class="d-flex justify-space-between text-subtitle-2 pt-0 pb-2">
                                        <span>Promo. Price</span>
                                        <span class="primary--text" v-text="item.promotion_price"></span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-tabs dense v-model="tab" >
                        <v-tab :key="index" v-for="(item, index) in work_dates" v-text="item.text" @click="selectWorkDate(item.value)"></v-tab>
                    </v-tabs>
                    <v-data-table calculate-widths hide-default-footer ref="table" class="elevation-0" loading-text="Loading... Please wait..." checkbox-color="primary" no-data-text="No data available yet" :loading="loading" :ripple="false" :items-per-page="300" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.outlet.text"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.start_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.end_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.break_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.total_hours"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.hourly_rate"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <span class="d-block" v-text="item.promoter.name"></span>
                                    <span class="d-block" v-text="item.promoter.mobile_number"></span>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-chip label small :ripple="false" :color="getStatusColor(item.status.value)"><span v-text="item.status.text"></span></v-chip>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-menu bottom offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click="openInfoDialog(item)">
                                                <v-list-item-title>View Info</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
        <info-dialog ref="infoDialog"></info-dialog>
    </div>
</template>


<script>

import DisplayLabel from '../../../shared/DisplayLabel.vue'
import TimesheetShiftInfoDialog from './TimesheetShiftInfoDialog.vue'

export default {
    name: 'TimesheetList',
    components: {
        displayLabel: DisplayLabel,
        infoDialog: TimesheetShiftInfoDialog,
    },
    data() {
        return {
            loading: false,
            tab: null,
            filters: {
                work_date: '',
            },
            object: {
                managers: []
            },
            permissions: {
                can_view: false
            },
            work_dates: [],
            skus: [],
            items: [],
            headers: [],
        }
    },
    computed: {
        infoDialog() {
            return this.$refs.infoDialog
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.loading = true
            this.tab = null
            this.items = []

            this.$store.dispatch('epanel/project/getCampaignTimesheet', { id: this.$route.params.id, object: this.filters }).then((response) => {
                this.object = response.data.object
                this.headers = response.data.headers
                this.items = response.data.items
                this.work_dates = response.data.work_dates
                this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        openInfoDialog: function(item) {
            this.infoDialog.updateObject({ campaign: this.$route.params.id, shift: item.id })
            this.infoDialog.open()
        },
        selectWorkDate: function(text) {
            this.filters.work_date = text
            this.get()
        },
        getStatusColor: function(name) {
            var colors = {'D': 'grey lighten-3', 'P': 'light-blue white--text', 'C': 'light-green white--text'}
            return colors[name]?colors[name]:'red white--text'
        }
    }
}

</script>


<style scoped></style>